import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("../components/HomeComponent.vue"),
        meta: {
            requiredAuth: true
        }
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../components/LoginComponent.vue"),
        meta: {
            requiredAuth: false
        }
    },
    {
        path: "/requests",
        name: "requests",
        component: () => import("../components/RequestsComponent.vue"),
        meta: {
            requiredAuth: true
        }
    }
];


const router = new VueRouter({
    routes
  })

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiredAuth)) {
        if (!localStorage.getItem("token")) {
            next({
                window: location.href = "https://calendar.qsee.kz/api/auth/login"
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
